import { Title } from "@solidjs/meta";
import { A, type RouteSectionProps } from "@solidjs/router";
import { useAppState } from "~/AppContext";

export default function Dash(props: RouteSectionProps) {
  const { t } = useAppState();

  return (
    <main class="max-w-screen-xl mx-auto h-full w-full flex flex-col box-border fixed md:static">
      <Title>{t("app.title")}</Title>
      <nav class="hidden md:flex h-10 flex-row px-1 my-4">
        <A
          href=""
          class="block font-semibold text-xl text-gray-600 dark:text-gray-400 cursor-pointer hover:text-black px-2 py-1 my-1 md:mx-1"
        >
          {t("account")}
        </A>
        <A
          href="knows"
          class="block font-semibold text-xl text-gray-600 dark:text-gray-400 cursor-pointer hover:text-black px-2 py-1 my-1 md:mx-1"
        >
          {t("propositions")}
        </A>
        <A
          href="implies"
          class="block font-semibold text-xl text-gray-600 dark:text-gray-400 cursor-pointer hover:text-black px-2 py-1 my-1 md:mx-1"
        >
          {t("implies")}
        </A>
        <A
          href="articles"
          class="block font-semibold text-xl text-gray-600 dark:text-gray-400 cursor-pointer hover:text-black px-2 py-1 my-1"
        >
          {t("articles")}
        </A>
        <span class="grow" />
      </nav>
      {props.children}
    </main>
  );
}
